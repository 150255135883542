
import Vue from 'vue';
import axios from 'axios';
import AudioUpload from "@/components/dialogs/AudioUpload.vue";
import Component from 'vue-class-component';
import { Audio } from '@/entities/audio/Audio';
import { bus } from "@/plugins/events";
@Component({
  components: {
    AudioUpload
  }
})
export default class AudioView extends Vue {
  fab = false;
  deleteDialog = false;
  editDialog = false;
  audio = {} as Audio;

  beforeMount() {
    this.fetchAudio();
  }

  fetchAudio() {
      axios.get(`/v1/audios/${this.$route.params.id}/info`).then((response: any) => {
        this.audio = response.data.audio;
      }).catch(() => {
        this.$router.go(-1);
      });
    }

    deleteAudio() {
      axios.delete(`/v1/audios/${this.$route.params.id}`).then(() => {
        this.$router.replace('/audios');
        bus.$emit('show-snack', {
          text: "Audio was deleted!",
          color: "success"
        });
      }).catch(() => {
        bus.$emit('show-snack', {
          text: "Couldn't delete this audio!",
          color: "error"
        });
      });
    }
}
